import React, { useEffect, useState } from "react";
import "./../styles/Purchase.scss";
import AppBar from "../components/AppBar/AppBar";
import { useLocation, useNavigate } from "react-router-dom";

function Purchase() {
  const navigate = useNavigate();
  const location = useLocation();

  // function begin(event) {
  //   event.preventDefault();
  //   navigate("/verify");
  // }

  useEffect(() => {
    if (
      location.state == undefined ||
      location.state == null ||
      location.state == ""
    ) {
      navigate("/");
    }
  }, []);

  return (
    <div className="purchase">
      <AppBar title="Purchase" isRoot={false}></AppBar>
      <div className="purchase-container">
        <p className="purchase-title">Confirm</p>
        <p className="purchase-text">Click Yes to complete your purchase.</p>
        <div className="purchase-btn-list">
          <div className="purchase-no-btn">
            <p className="purchase-no-btn-text">No</p>
          </div>
          <div className="purchase-yes-btn">
            <p className="purchase-yes-btn-text">Yes</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Purchase;
