import React, { useEffect, useState } from "react";
import "./../styles/VerifyCode.scss";
import AppBar from "../components/AppBar/AppBar";
import { useLocation, useNavigate, useHistory } from "react-router-dom";
import OTPInput from "react-otp-input";
import axios from "axios";
import { Bounce, toast } from "react-toastify";
import { TailSpin } from "react-loader-spinner";

function VerifyCode() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const location = useLocation();
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [resendCode, setResendCode] = useState(false);

  useEffect(() => {
    if (
      location.state == undefined ||
      location.state == null ||
      location.state == ""
    ) {
      navigate("/");
    }
  });

  function begin(event) {
    event.preventDefault();
    if (otp.length != 4) {
      toast.error("Invalid Verify Code", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    } else {
      verify();
    }
  }

  function resend(event) {
    event.preventDefault();
    setResendCode(true);
    // POST request using axios inside useEffect React hook
    let user;
    if (state.phone) {
      user = {
        email: state.email,
        password: state.password,
        firstname: state.firstName,
        lastname: state.lastName,
        phone: state.phone,
        roles: state.roles,
      };
    } else {
      user = {
        email: state.email,
        password: state.password,
        firstname: state.firstName,
        lastname: state.lastName,
        roles: state.roles,
      };
    }

    axios
      .post("https://api.am-english.app/api/v1/users/", user)
      .then((response) => {
        setResendCode(false);
      })
      .catch((error) => {
        setResendCode(false);
        // Error
        if (error.response) {
          if (error.response.data.hasOwnProperty("email")) {
            toast.error(error.response.data.email[0].toString(), {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Bounce,
            });
          } else if (error.response.data.hasOwnProperty("password")) {
            toast.error(error.response.data.password[0].toString(), {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Bounce,
            });
          }
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the
          // browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          // console.log('Error', error.message);
          toast.error(error.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        }
        // alert.error(error.config);
        // console.log(error.config);
      });
  }

  function verify() {
    // POST request using axios inside useEffect React hook
    setLoading(true);
    let code = {
      token: otp,
    };
    axios
      .post("https://api.am-english.app/api/v1/auth/verify-account/", code)
      .then((response) => {
        login();
      })
      .catch((error) => {
        setLoading(false);
        // Error
        if (error.response) {
          if (error.response.data.hasOwnProperty("message")) {
            toast.error(error.response.data.message.toString(), {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Bounce,
            });
          }
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the
          // browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          // console.log('Error', error.message);
          toast.error(error.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        }
        // alert.error(error.config);
        // console.log(error.config);
      });
  }

  function setToken(token) {
    localStorage.setItem("token", token);
  }

  function login() {
    // POST request using axios inside useEffect React hook
    let loginObject = {
      email: state.email,
      password: state.password,
    };
    axios
      .post("https://api.am-english.app/api/v1/auth/login/", loginObject)
      .then((response) => {
        setToken(response.data.access);
        setLoading(false);
        navigate("/score", {
          state: {
            questions: state.questions,
          },
        });
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the
          // browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          // console.log('Error', error.message);
          toast.error(error.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        }
        // alert.error(error.config);
        // console.log(error.config);
      });
  }

  return (
    <div className="verify-code">
      <AppBar title="Signup" isRoot={false}></AppBar>
      <div className="verify-code-content">
        <div className="verify-code-header">
          <h1 className="verify-code-header-title">
            {state.phone ? "SMS Verification" : "Email Verification"}
          </h1>
        </div>
        <div className="verify-code-header">
          <p className="verify-code-header-text">
            {state.phone
              ? "We sent a code to your Phone Number"
              : "We sent a code to your Email"}
          </p>
          <p className="verify-code-header-text">
            {state.phone ? state.phone : state.email}
          </p>
          <p
            onClick={(e) => {
              e.preventDefault();
              navigate("/signup", {
                state: {
                  country: state.phone ? 0 : 1,
                },
              });
            }}
            className="verify-code-header-change-text"
          >
            Change
          </p>
        </div>

        <OTPInput
          value={otp}
          onChange={setOtp}
          numInputs={4}
          inputStyle={{
            width: "3.5rem",
            height: "3.5rem",
            padding: "12px 20px",
            margin: "2rem 1rem",
            border: "none",
            display: "inline-block",
            borderRadius: "1rem",
            boxSizing: "border-box",
            fontFamily: "fredoka",
            color: "#080E1E",
            fontSize: "1rem",
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
            backgroundColor: "rgba(8, 14, 30, 0.05)",
          }}
          renderInput={(props) => <input {...props} />}
        />

        <div onClick={begin} className="verify-code-btn">
          {!loading && <p className="verify-code-btn-text">Signup</p>}
          {loading && (
            <TailSpin
              height="40"
              width="40"
              radius="4"
              color="white"
              ariaLabel="loading"
            />
          )}
        </div>
        <div className="verify-code-resend">
          <p className="verify-code-resend-text">Don’t receive your code?</p>
          <p
            onClick={resend}
            className={`verify-code-resend-${resendCode ? "text" : "btn"}`}
          >
            {resendCode ? "Sending..." : "Resend"}
          </p>
        </div>
      </div>
    </div>
  );
}

export default VerifyCode;
