import React, { useState } from "react";

import "./Style.scss";

function DayItem(props) {
  const [selected, setSelected] = useState(false);

  function select(event) {
    event.preventDefault();
    setSelected(!selected);
  }
  return (
    <div
      onClick={select}
      className={`day day-${selected ? "active" : "inactive"}`}
    >
      <p className={`day-text day-text-${selected ? "active" : "inactive"}`}>
        {props.title}
      </p>
    </div>
  );
}

export default DayItem;
