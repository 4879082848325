import React, { useEffect } from "react";
import "./../styles/Download.scss";
import AppBar from "../components/AppBar/AppBar";
import { useLocation, useNavigate } from "react-router-dom";

function goToAppStore(event) {
  event.preventDefault();
}

function goToPlayStore(event) {
  event.preventDefault();
  window.location.href =
    "https://play.google.com/store/apps/details?id=am.english.app";
}

function Download() {
  // const navigate = useNavigate();
  // const location = useLocation();

  // useEffect(() => {
  //   if (
  //     location.state == undefined ||
  //     location.state == null ||
  //     location.state == ""
  //   ) {
  //     navigate("/");
  //   }
  // }, []);

  return (
    <div className="download">
      <AppBar title="Download" isRoot={false}></AppBar>
      <div className="download-content">
        <img
          src={require("./../assets/images/all-set.png")}
          className="download-image"
        ></img>

        <div className="download-header">
          <h1 className="download-header-title">All Set.</h1>
        </div>

        <div onClick={goToPlayStore} className="download-btn">
          <p className="download-btn-text">
            Download AM English App for Android
          </p>
        </div>
        <div onClick={goToAppStore} className="download-btn">
          <p className="download-btn-text">Download AM English App for ios</p>
        </div>
      </div>
    </div>
  );
}

export default Download;
