import React, { useEffect, useState } from "react";
import "./../styles/Score.scss";
import AppBar from "../components/AppBar/AppBar";
import { useLocation, useNavigate, useHistory } from "react-router-dom";
import CourseList from "../components/CourseList/CourseList";
import CourseItem from "../components/CourseItem/CourseItem";
import axios from "axios";

function Score() {
  const navigate = useNavigate();
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    if (
      location.state == undefined ||
      location.state == null ||
      location.state == ""
    ) {
      navigate("/");
      return;
    }

    if (getToken()) {
      let config = {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      };
      axios
        .get("https://api.am-english.app/api/v1/courses/", config)
        .then((response) => {
          setCourses(response.data.results);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    }
  }, []);

  function begin(event) {
    event.preventDefault();
    navigate("/download");
  }

  function getToken() {
    const tokenString = localStorage.getItem("token");
    if (tokenString) {
      return tokenString;
    }
    return null;
  }

  return (
    <div className="score">
      <AppBar title="Score" isRoot={false}></AppBar>
      <div className="score-content">
        <div className="score-header">
          <h1 className="score-header-title">GREAT!</h1>
          <h2 className="score-header-score">Your Score 1/5</h2>
          <h1 className="score-header-text">Appropriate Courses for You</h1>
        </div>

        {!loading && (
          <CourseList>
            {courses.map((item, index) => {
              return <CourseItem item={item}></CourseItem>;
            })}
          </CourseList>
        )}
        <div onClick={begin} className="score-btn">
          <p className="score-btn-text">Skip</p>
        </div>
      </div>
    </div>
  );
}

export default Score;
