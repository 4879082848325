export let questions = [
  {
    title: "What is the main reason to learn English?",
    selected: null,
    answers: [
      {
        id: "1",
        title: "Travel",
        desc: null,
        point: 1,
        selected: false,
      },
      {
        id: "2",
        title: "Study",
        desc: null,
        point: 2,
        selected: false,
      },
      {
        id: "3",
        title: "Work",
        desc: null,
        point: 3,
        selected: false,
      },
      {
        id: "4",
        title: "Family/Friends",
        desc: null,
        point: 4,
        selected: false,
      },
      {
        id: "5",
        title: "Skill Improvement",
        desc: null,
        point: 5,
        selected: false,
      },
      {
        id: "6",
        title: "Others",
        desc: null,
        point: 6,
        selected: false,
      },
    ],
  },
  {
    title: "How much you know about English?",
    selected: null,
    answers: [
      {
        id: "7",
        title: "Not much",
        desc: null,
        point: 1,
        selected: false,
      },
      {
        id: "8",
        title: "Medium",
        desc: null,
        point: 2,
        selected: false,
      },
      {
        id: "9",
        title: "Expert",
        desc: null,
        point: 3,
        selected: false,
      },
    ],
  },
  {
    title: "Which level do you want to reach?",
    selected: null,
    answers: [
      {
        id: "10",
        title: "BASIC",
        desc: "Use familiareveryday expressions, such as introductions, details about yourself and your family.",
        point: 1,
        selected: false,
      },
      {
        id: "11",
        title: "Independent",
        desc: "Understand the main points when communications in everyday situations and can share your options.",
        point: 2,
        selected: false,
      },
      {
        id: "12",
        title: "Proficient",
        desc: "Communicate effectively and flexibility in most social, academic and professional contexts and understand indirect meaning.",
        point: 3,
        selected: false,
      },
    ],
  },
  {
    title: "How much time do you want to learn English?",
    selected: null,
    answers: [
      {
        id: "13",
        title: "15 min/Day",
        desc: null,
        point: 1,
        selected: false,
      },
      {
        id: "14",
        title: "30 min/Day",
        desc: null,
        point: 2,
        selected: false,
      },
      {
        id: "15",
        title: "45 min/Day",
        desc: null,
        point: 3,
        selected: false,
      },
      {
        id: "16",
        title: "60 min/Day",
        desc: null,
        point: 4,
        selected: false,
      },
    ],
  },
  {
    title: "When would you like to learn English?",
    selected: null,
    answers: [
      {
        id: "17",
        title: "Around breakfast",
        desc: null,
        point: 1,
        selected: false,
      },
      {
        id: "18",
        title: "On the go",
        desc: null,
        point: 2,
        selected: false,
      },
      {
        id: "19",
        title: "Around lunch time",
        desc: null,
        point: 3,
        selected: false,
      },
      {
        id: "20",
        title: "Around dinner time",
        desc: null,
        point: 4,
        selected: false,
      },
    ],
  },
];
