import React from "react";
import "./Style.scss";
import { useNavigate } from "react-router-dom";

function getTotalActivities(
  total_lesson,
  total_quiz,
  total_exercies,
  total_finaltest
) {
  return total_lesson + total_quiz + total_exercies + total_finaltest;
}

function CourseItem(props) {
  const navigate = useNavigate();

  function goToOverview(item) {
    navigate("/course-overview", {
      state: {
        item: item,
      },
    });
  }

  return (
    <div
      onClick={(event) => {
        event.preventDefault();
        goToOverview(props.item);
      }}
      className="course-item"
    >
      <div className="course-item-icon">
        <img
          src={
            (props.item.image != "") & (props.item.image != null)
              ? props.item.image
              : require("./../../assets/images/course.png")
          }
          alt={props.item.course_name}
          className="course-item-icon-image"
        ></img>
      </div>
      <div className="course-item-info">
        <p className="course-item-info-title">{props.item.course_name}</p>
        <p className="course-item-info-text"></p>
        <div className="course-item-info-progress">
          <div
            className="course-item-info-progress-done"
            style={{
              width: "0",
            }}
          ></div>
        </div>
      </div>
      <div className="course-item-data">
        <p className="course-item-info-text">
          {props.item.user_course_taken_count}/
          {getTotalActivities(
            props.item.total_lesson,
            props.item.total_quiz,
            props.item.total_exercies,
            props.item.total_finaltest
          )}
        </p>
      </div>
    </div>
  );
}

export default CourseItem;
