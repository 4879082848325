import React from "react";
import "./Style.scss";

function OverviewItem(props) {
  return (
    <div className="overview-item">
      <div className="overview-item-icon">
        <img src={props.icon} className="overview-item-icon"></img>
      </div>
      <p className="overview-item-text">{props.value}</p>
      <p className="overview-item-text">{props.property}</p>
    </div>
  );
}

export default OverviewItem;
