import React, { useState } from "react";
import "./../styles/SignUp.scss";
import AppBar from "../components/AppBar/AppBar";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import { Bounce, toast } from "react-toastify";
import { TailSpin } from "react-loader-spinner";

function SignUp() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const { state } = useLocation();
  const location = useLocation();

  useEffect(() => {
    if (
      location.state === undefined ||
      location.state === null ||
      location.state === ""
    ) {
      navigate("/");
    }
  });

  function begin(event) {
    event.preventDefault();
    setLoading(true);
    if (email === "") {
      toast.error("Enter Your Email Address", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      setLoading(false);
    } else if (password === "") {
      toast.error("Enter Your Password", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      setLoading(false);
    } else if (password.length < 6) {
      toast.error("Your Password must be at least 6 characters", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      setLoading(false);
    } else if (password !== confirmPassword) {
      toast.error("Your passwords do not match", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      setLoading(false);
    } else if (firstName === "") {
      toast.error("Enter Your First Name", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      setLoading(false);
    } else if (lastName === "") {
      toast.error("Enter Your Last Name", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      setLoading(false);
    } else if (state.country === 0) {
      if (phone === "") {
        toast.error("Enter Your Phone Number", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        setLoading(false);
      } else {
        signup();
      }
    } else {
      signup();
    }
  }

  // function setToken(token) {
  //   localStorage.setItem("token", token);
  // }

  function signup() {
    // POST request using axios inside useEffect React hook
    let user;
    if (state.country === 0) {
      user = {
        email: email,
        password: password,
        firstname: firstName,
        lastname: lastName,
        phone: phone,
        roles: ["STUDENT"],
      };
    } else {
      user = {
        email: email,
        password: password,
        firstname: firstName,
        lastname: lastName,
        roles: ["STUDENT"],
      };
    }

    axios
      .post("https://api.am-english.app/api/v1/users/", user)
      .then((response) => {
        setLoading(false);
        if (state.country === 0) {
          navigate("/verify", {
            state: {
              phone: phone,
              email: email,
              password: password,
              firstname: firstName,
              lastname: lastName,
              roles: ["STUDENT"],
              questions: state.questions,
            },
          });
        } else {
          setLoading(false);
          navigate("/verify", {
            state: {
              email: email,
              password: password,
              firstname: firstName,
              lastname: lastName,
              roles: ["STUDENT"],
              questions: state.questions,
            },
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        // Error
        if (error.response) {
          if (error.response.data.hasOwnProperty("email")) {
            alert.error(error.response.data.email[0].toString());
          } else if (error.response.data.hasOwnProperty("password")) {
            alert.error(error.response.data.password[0].toString());
          }
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the
          // browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          // console.log('Error', error.message);
          alert.error(error.message);
        }
        // alert.error(error.config);
        // console.log(error.config);
      });
  }
  return (
    <div className="sign-up">
      <AppBar title="Signup" isRoot={false}></AppBar>
      <div className="sign-up-content">
        <div className="sign-up-header">
          <h1 className="sign-up-header-title">See Your Results Now!</h1>
        </div>

        <form className="sign-up-content-form">
          <label className="label" for="fname">
            First Name
          </label>

          <input
            className="input"
            type="text"
            id="fname"
            name="firstname"
            onChange={(event) => {
              setFirstName(event.target.value);
            }}
            placeholder="Raha"
          ></input>
          <label className="label" for="lname">
            Last Name
          </label>

          <input
            className="input"
            type="text"
            id="lname"
            name="lastname"
            onChange={(event) => {
              setLastName(event.target.value);
            }}
            placeholder="Ghorbani"
          ></input>
          <label className="label" for="email">
            Email Address
          </label>

          <input
            className="input"
            type="email"
            id="email"
            name="email"
            onChange={(event) => {
              setEmail(event.target.value);
            }}
            placeholder="example@example.com"
          ></input>
          {location.state !== null && state.country === 0 && (
            <label className="label" for="phone">
              Phone Number
            </label>
          )}

          {location.state !== null && state.country === 0 && (
            <input
              className="input"
              type="tel"
              id="phone"
              name="phone"
              onChange={(event) => {
                setPhone(event.target.value);
              }}
              placeholder="example:  09121111111"
            ></input>
          )}
          <label className="label" for="password">
            Password
          </label>

          <input
            className="input"
            type="password"
            id="password"
            name="password"
            onChange={(event) => {
              setPassword(event.target.value);
            }}
            placeholder="Password"
          ></input>
          <label className="label" for="confirm-password">
            Confirm Password
          </label>

          <input
            className="input"
            type="password"
            id="confirm-password"
            name="confirm-password"
            onChange={(event) => {
              setConfirmPassword(event.target.value);
            }}
            placeholder="Confirm Password"
          ></input>
        </form>

        <div onClick={begin} className="sign-up-btn">
          {!loading && <p className="sign-up-btn-text">See Result</p>}
          {loading && (
            <TailSpin
              height="40"
              width="40"
              radius="4"
              color="white"
              ariaLabel="loading"
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default SignUp;
