import React, { useReducer, useState } from "react";
import "./../styles/Quiz.scss";
import AppBar from "../components/AppBar/AppBar";
import { questions } from "../components/data/QuestionsData";
import QuestionList from "../components/QuestionList/QuestionList";
import QuestionItem from "../components/QuestionItem/QuestionItem";
import DayItem from "../components/DayItem/DayItem";
import { useNavigate } from "react-router-dom";
import { Bounce, toast } from "react-toastify";

function Quiz() {
  const [index, setIndex] = useState(0);
  const [allQuestions, setAllQuestions] = useState(questions);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const navigate = useNavigate();

  function next(event) {
    event.preventDefault();
    if (index < allQuestions.length - 1) {
      if (
        allQuestions[index].selected != false &&
        allQuestions[index].selected != null
      ) {
        setIndex(index + 1);
      } else {
        toast.error("Choose an answer", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }
    } else {
      navigate("/select-country", {
        state: {
          questions: allQuestions,
        },
      });
    }
  }

  return (
    <div className="quiz">
      <AppBar
        title={`Question ${index + 1}/${allQuestions.length}`}
        isRoot={false}
      ></AppBar>
      <div className="quiz-content">
        <div className="quiz-header">
          <h1 className="quiz-header-title">{questions[index].title}</h1>
        </div>

        <QuestionList type="vertical">
          {allQuestions[index].answers.map((answer, offset) => {
            return (
              <QuestionItem
                title={answer.title}
                desc={answer.desc}
                selected={
                  allQuestions[index].selected ==
                  allQuestions[index].answers[offset].id
                }
                onClick={() => {
                  let item = allQuestions;

                  item[index].selected = item[index].answers[offset].id;
                  setAllQuestions(item);
                  forceUpdate();
                }}
              ></QuestionItem>
            );
          })}
        </QuestionList>

        {index === allQuestions.length - 1 ? (
          <div className="quiz-header">
            <h1 className="quiz-header-title">How often?</h1>
          </div>
        ) : (
          <div></div>
        )}
        {index === allQuestions.length - 1 ? (
          <QuestionList type="horizontal">
            <DayItem title="S"></DayItem>
            <DayItem title="M"></DayItem>
            <DayItem title="T"></DayItem>
            <DayItem title="W"></DayItem>
            <DayItem title="T"></DayItem>
            <DayItem title="F"></DayItem>
          </QuestionList>
        ) : (
          <div></div>
        )}

        <div onClick={next} className="quiz-btn">
          <p className="quiz-btn-text">Next</p>
        </div>
      </div>
    </div>
  );
}

export default Quiz;
