import React, { useEffect, useState } from "react";
import "./../styles/CourseOverview.scss";
import AppBar from "../components/AppBar/AppBar";
import OverviewList from "../components/OverviewList/OverviewList";
import OverviewItem from "../components/OverviewItem/OverviewItem";
import { useLocation, useNavigate } from "react-router-dom";

function CourseOverview() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const location = useLocation();

  useEffect(() => {
    if (
      location.state == undefined ||
      location.state == null ||
      location.state == ""
    ) {
      navigate("/");
    }
  }, []);

  function begin(event) {
    event.preventDefault();
    navigate("/download");
  }

  return (
    <div className="course-overview">
      <AppBar title="Course Overview" isRoot={false}></AppBar>
      <div className="course-overview-content">
        <div className="course-overview-header">
          <h1 className="course-overview-header-title">
            {state.item.course_name}
          </h1>
        </div>

        <OverviewList>
          <OverviewItem
            icon={require("./../assets/images/1.png")}
            property="on-demand video"
            value={state.item.total_video}
          ></OverviewItem>
          <OverviewItem
            icon={require("./../assets/images/2.png")}
            property="Lessons"
            value={state.item.total_lesson}
          ></OverviewItem>
          <OverviewItem
            icon={require("./../assets/images/3.png")}
            property="Quizzes"
            value={state.item.total_quiz}
          ></OverviewItem>
          <OverviewItem
            icon={require("./../assets/images/4.png")}
            property="Exercises"
            value={state.item.total_exercies}
          ></OverviewItem>
          <OverviewItem
            icon={require("./../assets/images/5.png")}
            property="lifetime access"
            value="Full"
          ></OverviewItem>
          <OverviewItem
            icon={require("./../assets/images/6.png")}
            property="Support"
            value="24/7"
          ></OverviewItem>
        </OverviewList>
        <div onClick={begin} className="course-overview-btn">
          <p className="course-overview-btn-text">Skip</p>
        </div>
      </div>
    </div>
  );
}

export default CourseOverview;
