import React from "react";
import "./../styles/StartQuiz.scss";
import AppBar from "../components/AppBar/AppBar";
import { useNavigate } from "react-router-dom";

function StartQuiz() {
  const navigate = useNavigate();

  function begin(event) {
    event.preventDefault();
    navigate("/quiz");
  }
  return (
    <div className="start-quiz">
      <AppBar title="Assessment Quiz" isRoot={false}></AppBar>
      <div className="start-quiz-content">
        <div className="start-quiz-header">
          <h1 className="start-quiz-header-title">First, Let’s See</h1>
          <h1 className="start-quiz-header-title">How Good You Are...</h1>
        </div>
        <img
          src={require("./../assets/images/assessment.png")}
          className="start-quiz-image"
        ></img>

        <div onClick={begin} className="start-quiz-btn">
          <p className="start-quiz-btn-text">Begin Quiz</p>
        </div>
      </div>
    </div>
  );
}

export default StartQuiz;
