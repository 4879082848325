import React, { useEffect, useState } from "react";
import "./../styles/SelectCountry.scss";
import AppBar from "../components/AppBar/AppBar";
import { useLocation, useNavigate, useHistory } from "react-router-dom";
import { Bounce, toast } from "react-toastify";

function SelectCountry() {
  const navigate = useNavigate();
  const [country, setCountry] = useState(-1);
  const location = useLocation();

  useEffect(() => {
    if (
      location.state == undefined ||
      location.state == null ||
      location.state == ""
    ) {
      navigate("/");
    }
  });

  function begin(event) {
    event.preventDefault();
    if (country !== -1) {
      navigate("/signup", {
        state: {
          country: country,
          questions: location.state.questions,
        },
      });
    } else {
      toast.error("Please select your country", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    }
  }
  return (
    <div className="select-country">
      <AppBar title="Country" isRoot={false}></AppBar>
      <div className="select-country-content">
        <div className="select-country-header">
          <h1 className="select-country-header-title">Almost there!</h1>
        </div>
        <img
          src={require("./../assets/images/select-country.png")}
          className="select-country-image"
        ></img>

        <div className="select-country-content-info">
          <p className="select-country-content-info-text">
            Where are you based?
          </p>
        </div>
        <div className="select-country-select-btn-list">
          <div
            onClick={() => {
              setCountry(0);
            }}
            className={`select-country-select-btn${
              country === 0 ? " select-country-select-btn-selected" : ""
            }`}
          >
            <p className="select-country-select-btn-text">Iran</p>
          </div>
          <div
            onClick={() => {
              setCountry(1);
            }}
            className={`select-country-select-btn${
              country === 1 ? " select-country-select-btn-selected" : ""
            }`}
          >
            <p className="select-country-select-btn-text">Outside Iran</p>
          </div>
        </div>
        <div onClick={begin} className="select-country-btn">
          <p className="select-country-btn-text">Continue</p>
        </div>
      </div>
    </div>
  );
}

export default SelectCountry;
