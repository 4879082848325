import React from "react";
import "./../styles/Landing.scss";
import AppBar from "../components/AppBar/AppBar";
import { useNavigate } from "react-router-dom";

function Landing() {
  const navigate = useNavigate();

  function start(event) {
    event.preventDefault();
    navigate("/start-quiz");
  }
  return (
    <div className="landing">
      <AppBar title="Welcome" isRoot={true} onClick={() => {}}></AppBar>
      <div className="landing-content">
        <div className="landing-header">
          <h1 className="landing-header-title">AM English</h1>
          <h1 className="landing-header-title">By Atefeh</h1>
        </div>
        <div className="landing-footer">
          <p className="landing-footer-text">Begin Your Assessment Quiz </p>
          <div onClick={start} className="landing-footer-btn">
            <p className="landing-footer-btn-text">Start Now</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Landing;
