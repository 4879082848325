import { BrowserRouter, Route, Routes } from "react-router-dom";

// import { useSelector, useDispatch } from "react-redux";
import Landing from "./pages/Landing";
import StartQuiz from "./pages/StartQuiz";
import Quiz from "./pages/Quiz";
import SelectCountry from "./pages/SelectCountry";
import SignUp from "./pages/SignUp";
import VerifyCode from "./pages/VerifyCode";
import Score from "./pages/Score";
import CourseOverview from "./pages/CourseOverview";
import Purchase from "./pages/Purchase";
import Download from "./pages/Download";

function App() {
  // const { message, open, type } = useSelector((state) => state.alert);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing></Landing>}>
          <Route index element={<Landing></Landing>}></Route>
          <Route path="" element={<Landing></Landing>}></Route>
        </Route>
        <Route path="/start-quiz" element={<StartQuiz></StartQuiz>}></Route>
        <Route path="/quiz" element={<Quiz></Quiz>}></Route>
        <Route
          path="/select-country"
          element={<SelectCountry></SelectCountry>}
        ></Route>
        <Route path="/signup" element={<SignUp></SignUp>}></Route>
        <Route path="/verify" element={<VerifyCode></VerifyCode>}></Route>
        <Route path="/score" element={<Score></Score>}></Route>
        <Route
          path="/course-overview"
          element={<CourseOverview></CourseOverview>}
        ></Route>
        <Route path="/purchase" element={<Purchase></Purchase>}></Route>
        <Route path="/download" element={<Download></Download>}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
