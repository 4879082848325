import React from "react";
import "./Style.scss";

function AppBar(props) {
  return (
    <div className="app-bar">
      <div className="app-bar-leading"></div>
      {/* <div
        onClick={props.onClick}
        className={`app-bar-${props.isRoot ? "close" : "back"}`}
      ></div> */}
      <p className="app-bar-title">{props.title}</p>
      <div className="app-bar-leading"></div>
    </div>
  );
}

export default AppBar;
