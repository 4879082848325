import React from "react";
import "./Style.scss";

function QuestionList(props) {
  return (
    <div
      className={`question-list${
        props.type == "vertical" ? "" : "-horizontal"
      }`}
    >
      {props.children}
    </div>
  );
}

export default QuestionList;
