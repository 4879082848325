import React from "react";

import "./Style.scss";

function QuestionItem(props) {
  return (
    <div
      onClick={props.onClick}
      className={`question question-${props.selected ? "active" : "inactive"}`}
    >
      <div className="question-icon">
        <img
          src={require("./../../assets/images/question-icon.png")}
          className="question-icon-image"
        ></img>
      </div>
      <div className="question-info">
        <p
          className={`question-text question-text-${
            props.selected ? "active" : "inactive"
          }`}
        >
          {props.title}
        </p>
        {props.desc ? (
          <p
            className={`question-desc question-desc-${
              props.selected ? "active" : "inactive"
            }`}
          >
            {props.desc}
          </p>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
}

export default QuestionItem;
